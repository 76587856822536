@import "./fonts.css";

:root {
    /* FONTS */
    --Inter: "Inter", sans-serif;
    --systemFontStack: system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
        Cantarell, "Helvetica Neue", sans-serif;
}

@supports (font-variation-settings: normal) {
    :root {
        --Inter: "Inter Var", sans-serif;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--Inter);
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
    font-size: 16px;
    text-size-adjust: 100%;
    height: 100vh;
    width: 100vw;
}

body {
    position: relative;
    display: block;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: normal;
    word-wrap: break-word;
    font-kerning: normal;
    font-feature-settings: "kern", "liga", "clig", "calt";
    height: 100vh;
    width: 100vw;
}

div,
article,
aside,
details,
figcaption,
code,
figure,
footer,
header,
main,
menu,
nav,
section,
summary,
audio,
canvas,
progress,
video,
ul,
ol,
img {
    display: block;
    box-sizing: border-box;
}

main {
    position: relative;
    display: flex;
    flex-direction: column;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

progress {
    vertical-align: baseline;
}

[hidden],
template {
    display: none;
}

h1,
h2,
h3,
h4,
h5 {
    display: block;
    margin: 0 0 1rem 0;
}

a {
    text-decoration: none;
    background-color: transparent;
    outline: 0;
}

a:active,
a:hover {
    text-decoration: none;
    outline-width: 0;
}

p {
    margin: 0 0 1rem 0;
}

ol,
ul {
    margin-top: 0;
}

abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    border-bottom: none;
}

b,
strong {
    font-weight: inherit;
    font-weight: bolder;
}

dfn {
    font-style: italic;
}

mark {
    color: #000;
    background-color: #ff0;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
}

button,
input {
    overflow: visible;
    box-sizing: border-box;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 500px white inset;
}

button,
select {
    background-color: transparent;
    text-transform: none;
    border: none;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

button:active,
button:focus {
    outline: 0;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
    -webkit-appearance: button;
}

fieldset {
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
    border: 1px solid silver;
}

legend {
    display: table;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0;
    color: inherit;
    white-space: normal;
}

textarea {
    overflow: auto;
    box-sizing: border-box;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

.modalOpen {
    margin: 0;
    overflow: hidden;
}

.ContentEditableWrapper {
    position: relative;
    width: 100%;
    border: 2px #e7e7e7 solid;
    border-radius: 8px;
    padding: 12px 16px;
    height: 100%;
}
.ContentEditable-Fake {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: transparent;
    background: #f7f7f7;
    padding: 12px 16px;
    height: 100%;
}
.ContentEditableWrapper span {
    background-color: yellow;
}

.ContentEditable-Input {
    height: 100%;
}

.ContentEditable-Input:empty:before {
    content: attr(placeholder);
    cursor: text;
    display: block;
    color: rgba(0, 0, 0, 0.25);
}
